<script lang="ts" setup>
withDefaults(defineProps<{
  placeholder?: string,
  error?: null | string,
  type?: 'number' | 'text' | 'email' | 'password' | 'date' | 'time',
  disabled?: boolean,
  min?: string,
}>(), {
  type: 'text',
  error: null,
  disabled: false,
  placeholder: '',
  min: '',
});

const modelValue = defineModel<null|string>();
</script>
<template>
  <div class="flex flex-col">
    <input
      v-model="modelValue"
      :type="type"
      :class="[
        'block w-full rounded-md border-0 py-[.44rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm leading-6',
        { 'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200': disabled },
        { 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500': error }
      ]"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
    >
    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </p>
  </div>
</template>
